import * as React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Typography, Box, FormControl, TextField } from '@mui/material';
import { withRouter } from '../common/with-router';

function AddMouse(props) {
  const defaultMouseData = {
    id: '',
    generation: '',
    age: '',
    claim: '',
    gender: '',
    dob: '',
    weanDate: '',
    strain: '',
    notes: '',
    genotype: '',
    notchNum: '',
  };

  const selectedMouseId = props.selections.length > 0 ? props.selections[0] : null;
  const initialMouseData = props.mice.find(mouse => mouse.id === selectedMouseId) || defaultMouseData;
  const [mouseData, setMouseData] = React.useState({ ...initialMouseData });
  const [showAllFields, setShowAllFields] = React.useState(false);

  const handleFieldChange = (field) => (event) => {
    setMouseData({
      ...mouseData,
      [field]: event.target.value
    });
  };

  const handleSubmit = () => {
    // Add cageID to mouseData
    const updatedMouseData = {
      ...mouseData,
      cageId: props.cage.id,
    };
  
    // Validate fields before submitting
    // const missingFields = Object.keys(updatedMouseData).filter(key => !updatedMouseData[key]);
    // if (missingFields.length > 0) {
    //   alert(`The following fields are required: ${missingFields.join(', ')}`);
    //   return;
    // }
  
    // Submit the updated mouse data
    console.log(mouseData);
    props.onSubmit(updatedMouseData);
    props.onClose();
  };

  return (
    <>
      <DialogTitle alignSelf="center">Add a New Mouse</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, marginTop: 1}}>
          {Object.keys(defaultMouseData)
            .filter(field => field !== 'id') // Hide the ID field
            .filter(field => showAllFields || mouseData[field]) // Toggle visibility based on filled fields
            .map((field, index) => (
              <FormControl key={index} fullWidth>
                <TextField
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  value={mouseData[field] || ''}
                  onChange={handleFieldChange(field)}
                />
              </FormControl>
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowAllFields(!showAllFields)}>
          {showAllFields ? 'Hide Empty Fields' : 'Show All Fields'}
        </Button>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </>
  );
}

export default withRouter(AddMouse);
